* {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
  background-color: #eef2f4;

  font-family: "Inter-Regular";
}

.delete-input-confirm{
  width: 90%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid lightgray;
  margin-top: 10px;
}
.delivery-fees-btn {
  width: 220px;
}

.mt-5 {
  margin-top: 5px;
}
.App {
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("./fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./fonts/Inter-Bold.ttf");
}
