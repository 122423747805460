.google-auth {
    color: #063f71;
    text-decoration: none;
}

.google-auth-wrapper {
    display: flex;
    align-items: center;
}

.google-paragraph {
    margin-left: 5px;
}
